import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import emailjs from '@emailjs/browser'; 
import { setName, setEmail, setPhone } from './features/dataFormSlice'

const ContactUs = () => {


  const fullData = useSelector((state) => state.data)
  const dispatch = useDispatch()

  const [emailSucess, setEmailSucess] = useState(false)



  const submitHandler = (e) => {
    e.preventDefault();
    if(fullData.name === "" || fullData.email === "" || fullData.phone === ""){
        alert("יש למלא את כל הפרטים")
        return null;
    }
    

    //SEND THE EMAIL
    const serviceId = 'dimenSight_email';
    const templateId = 'template_jdr81yu';
    const publicKey = 'iF_LIO_CbqSaJW1Gu';

    const templateParams = {
        to_name: "תומר",
        from_name: fullData.name,
        from_email: fullData.email,
        from_phone: fullData.phone,
        from_webType: fullData.webType + " - טופס רגיל",
        from_Target: fullData.webTarget + " - טופס רגיל"

    }

    emailjs.send(serviceId, templateId, templateParams, publicKey)
        .then((res) => {
            if(res.status === 200)
              setEmailSucess(true);
        })

        .catch((err) => {
            console.error("Something Wrong: ", err)
        })



}
    //-----------------------------------------------------------------//



  return (
    <div className='w-full h-4/6 md:h-3/6 bg-brand-blue flex flex-col justify-center items-center'>
      {!emailSucess?
      

        <form onSubmit={(e)=> submitHandler(e)} className='w-3/4 md:w-1/2 flex flex-col justify-center items-center gap-8 m-16'>
            <h3 className='text-center text-brand-yellow text-xl'>מעוניין\ת באתר אינטרנט<br />שיקדם אותך צעד לפני המתחרים?</h3>
            <input type="text" name="Name" placeholder='שם מלא' className='md:w-full border-b-2 bg-transparent text-brand-white text-2xl' onChange={(e) => dispatch(setName(e.target.value))} />
            <input type="email" name="Email" placeholder='מייל' className='md:w-full border-b-2 bg-transparent text-brand-white text-2xl' onChange={(e) => dispatch(setEmail(e.target.value))}/>
            <input type="tel" dir='rtl' name="Phone" placeholder='נייד' className='md:w-full border-b-2 bg-transparent text-brand-white text-2xl' onChange={(e) => dispatch(setPhone(e.target.value))}/>
            <button className='w-full md:w-full bg-brand-yellow text-brand-blue text-2xl rounded-full p-2'>שלח</button>
        </form>
      :

      <div className='w-full h-full flex flex-col items-center justify-center text-brand-white'>
        <div className='w-5/6 text-center'>
          <h3 className='text-2xl'>היי {fullData.name}</h3>
          <p className='text-lg'>כיף שפנית אלי! פרטיך התקבלו בהצלחה.</p>
          <p className='text-lg'>בקרוב מאוד אני אחזור אליך כדי להבין לעומק את הצורך ולתת את ההצעה הכי מותאמת שיכולה להיות בשבילך!</p>
        </div>
      </div>
      }
    </div>
  )
}

export default ContactUs